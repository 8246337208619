define("discourse/plugins/user-autonomy-plugin/discourse/components/modal/tell-reason-form", ["exports", "@glimmer/component", "@glimmer/tracking", "@ember/modifier", "@ember/object", "discourse/components/conditional-loading-spinner", "discourse/components/d-button", "discourse/components/d-modal", "discourse/components/d-modal-cancel", "discourse/lib/ajax-error", "discourse-common/helpers/i18n", "@ember/component", "@ember/template-factory"], function (_exports, _component, _tracking, _modifier, _object, _conditionalLoadingSpinner, _dButton, _dModal, _dModalCancel, _ajaxError, _i18n, _component2, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  class TellReasonForm extends _component.default {
    static #_ = (() => dt7948.g(this.prototype, "loading", [_tracking.tracked], function () {
      return false;
    }))();
    #loading = (() => (dt7948.i(this, "loading"), void 0))();
    static #_2 = (() => dt7948.g(this.prototype, "reason", [_tracking.tracked], function () {
      return "";
    }))();
    #reason = (() => (dt7948.i(this, "reason"), void 0))();
    async submit() {
      this.loading = true;
      try {
        await this.args.model.submit(this);
      } catch (err1) {
        (0, _ajaxError.popupAjaxError)(err1);
      }
      this.loading = false;
      this.close();
    }
    static #_3 = (() => dt7948.n(this.prototype, "submit", [_object.action]))();
    close() {
      this.args.closeModal();
    }
    static #_4 = (() => dt7948.n(this.prototype, "close", [_object.action]))();
    updateValue(event1) {
      event1.preventDefault();
      this.reason = event1.target.value;
    }
    static #_5 = (() => dt7948.n(this.prototype, "updateValue", [_object.action]))();
    static #_6 = (() => (0, _component2.setComponentTemplate)((0, _templateFactory.createTemplateFactory)(
    /*
      
        <form class="request-before-topic-op-admin-action-form">
          <DModal
            @title={{i18n "topic_op_admin.reason_modal.require_reason"}}
            @closeModal={{@closeModal}}
          >
            <:body>
              <div class="control-group">
                <p>
                  {{i18n "topic_op_admin.reason_modal.tell_reason"}}
                </p>
    
                <textarea
                  value={{this.reason}}
                  {{on "input" this.updateValue}}
                  min-width="500px"
                  min-height="400px"
                  max-height="1000px"
                  resize="both"
                />
              </div>
            </:body>
    
            <:footer>
              <DButton
                @class="btn-primary"
                @label="ok_value"
                @action={{this.submit}}
                @disabled={{this.loading}}
              />
              <DModalCancel @close={{@closeModal}} />
              <ConditionalLoadingSpinner
                @size="small"
                @condition={{this.loading}}
              />
            </:footer>
          </DModal>
        </form>
      
    */
    {
      "id": "MXTOH1y5",
      "block": "[[[1,\"\\n    \"],[10,\"form\"],[14,0,\"request-before-topic-op-admin-action-form\"],[12],[1,\"\\n      \"],[8,[32,0],null,[[\"@title\",\"@closeModal\"],[[28,[32,1],[\"topic_op_admin.reason_modal.require_reason\"],null],[30,1]]],[[\"body\",\"footer\"],[[[[1,\"\\n          \"],[10,0],[14,0,\"control-group\"],[12],[1,\"\\n            \"],[10,2],[12],[1,\"\\n              \"],[1,[28,[32,1],[\"topic_op_admin.reason_modal.tell_reason\"],null]],[1,\"\\n            \"],[13],[1,\"\\n\\n            \"],[11,\"textarea\"],[16,2,[30,0,[\"reason\"]]],[24,\"min-width\",\"500px\"],[24,\"min-height\",\"400px\"],[24,\"max-height\",\"1000px\"],[24,\"resize\",\"both\"],[4,[32,2],[\"input\",[30,0,[\"updateValue\"]]],null],[12],[13],[1,\"          \"],[13],[1,\"\\n        \"]],[]],[[[1,\"\\n          \"],[8,[32,3],null,[[\"@class\",\"@label\",\"@action\",\"@disabled\"],[\"btn-primary\",\"ok_value\",[30,0,[\"submit\"]],[30,0,[\"loading\"]]]],null],[1,\"\\n          \"],[8,[32,4],null,[[\"@close\"],[[30,1]]],null],[1,\"\\n          \"],[8,[32,5],null,[[\"@size\",\"@condition\"],[\"small\",[30,0,[\"loading\"]]]],null],[1,\"\\n        \"]],[]]]]],[1,\"\\n    \"],[13],[1,\"\\n  \"]],[\"@closeModal\"],false,[]]",
      "moduleName": "/var/www/discourse/app/assets/javascripts/discourse/discourse/plugins/user-autonomy-plugin/discourse/components/modal/tell-reason-form.js",
      "scope": () => [_dModal.default, _i18n.default, _modifier.on, _dButton.default, _dModalCancel.default, _conditionalLoadingSpinner.default],
      "isStrictMode": true
    }), this))();
  }
  _exports.default = TellReasonForm;
});